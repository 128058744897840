//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import { today } from "@/utils/dates";
import { plural } from "@/utils/numbers";
export default {
  mixins: [ModalMixin],
  props: {
    id: {
      required: false
    }

    /*
    result: {
        type: Object,
        required: true,
    },
    */
  },

  data() {
    return {
      result: null,
      onComplete: null
    };
  },
  mounted() {},
  methods: {
    onOpen({
      res2,
      onComplete
    }) {
      //console.log('63: res2=', res2)
      this.result = res2;
      //console.log('65: this.result=', this.result)

      this.onComplete = onComplete;
    },
    downloadList(e) {
      e.preventDefault();
      let string = "";
      this.result.not_found_sku.forEach(item => {
        string += item + "\n";
      });
      const url = window.URL.createObjectURL(new Blob([string]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `Не найденные товары или товары с ошибками ${today("DD.MM.YYYY HH:mm")}`;
      link.setAttribute("download", `${fileName}.txt`); //&:

      document.body.appendChild(link);
      link.click();
    },
    async submit({
      close
    }) {
      close();
      //this.onComplete && this.onComplete(response)
      this.$emit('complete');
    }
  },
  computed: {
    pluralProductsNotFound() {
      return plural(this.result.not_found_sku.length, ["{v} sku не найден или указан с ошибками", "{v} sku не найдены или указаны с ошибками", "{v} sku не найдены или указаны с ошибками"]);
    },
    pluralSkuUpdate() {
      //console.log('106: this.result.totUpdateSku=', this.result.totUpdateSku)

      return plural(this.result.totUpdateSku, ["{v} sku обновлен", "{v} sku обновлены", "{v} sku обновлены"]);
    },
    pluralSkuAdd() {
      return plural(this.result.totAddListSku, ["{v} sku добавлен", "{v} sku добавлены", "{v} sku добавлены"]);
    },
    actions() {
      return [{
        name: "submit",
        text: "Спасибо",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        }
      }];
    }
  },
  components: {
    Modal
  }
};