//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import Icon from "../../assets/images/highlights/1.svg";
import ImportBtn2 from "@/components/PriceVar/ImportBtn2.vue";
import { plural } from "@/utils/numbers";
export default {
  mixins: [ModalMixin],
  props: {
    id: {
      required: false
    }
  },
  data() {
    return {
      onComplete: null,
      loading: false,
      valid: false,
      file: null,
      hash: null,
      Icon,
      //res_String: "",
      res1: null,
      //res1_String: "",
      res2: null,
      loadingStarted: null,
      aSkuRrc: [],
      badRrcXlsFormat: null
    };
  },
  mounted() {},
  methods: {
    ImportSkuRrc(rows) {
      //console.log('rows=', rows);
      this.aSkuRrc = rows;
      this.badRrcXlsFormat = null;

      /*
      queries.forEach((r) => {
          //this.queries += row + "\n";
          //console.log('r=', r);
      });
      */

      this.valid = true;
    },
    onOpen({
      onComplete
    }) {
      this.res1 = null;
      this.res2 = null;
      this.loadingStarted = null;
      this.aSkuRrc = [];
      this.badRrcXlsFormat = null;
      this.valid = false;
      this.onComplete = onComplete;
    },
    /*
    uploadFile(e) {
        this.file = e.target.files;
        this.valid = true;
    },
    dragFile(e) {
        this.file = e.dataTransfer.files;
        this.valid = true;
    },
    */
    async submit({
      close
    }) {
      this.loading = true;
      this.badRrcXlsFormat = null;
      try {
        this.res1 = null;
        // if (
        //     this.$store.state.connections.marketplace.title ===
        //     "Wildberries"
        // ) {
        this.loadingStarted = "Загрузка началась";
        this.res1 = await this.$store.dispatch("priceVar/importRRC", {
          //id: this.id,
          id: this.$route.params.id,
          //file: this.file[0],
          aSkuRrc: this.aSkuRrc
        });
        this.loadingStarted = null;

        //                     this.res1_String =
        // `Статистика загрузки :
        // <br/>${this.res1.totNotPidSku} - новых sku (не было в БД)
        // <br/>${this.res1.totUpdateSku} - обновлены
        // <br/>${this.res1.totAddListSku} - добавлены в список
        // `

        // }
        // if (
        //     this.$store.state.connections.marketplace.title === "OZON"
        // ) {
        //     result = await this.$store.dispatch(
        //         "connections/importOzProducts",
        //         {
        //             file: this.file[0],
        //         }
        //     );
        // }

        //console.log("submit: res1=", this.res1);

        //this.hash = result?.data.hash;
        setInterval(() => {
          if (this.loading) {
            this.loadProgress(close);
          }
        }, 3000);
      } catch (e) {
        var _e$response;
        this.loading = false;
        if (((_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.status) === 409) {
          this.badRrcXlsFormat = "Неверный формат данных файла списка товаров и РРЦ";
        }
      }
    },
    async loadProgress(close) {
      //console.log("loadProgress: STR");

      //let res = null;
      // if (
      //     this.$store.state.connections.marketplace.title ===
      //     "Wildberries"
      // ) {
      this.res2 = await this.$store.dispatch("priceVar/importRRCProgress", {
        //hash: this.hash,
        id: this.$route.params.id
      });

      //                 this.res_String = this.res1_String + `
      // <br/>${this.res2.totNewSku_End} товаров осталось добавить в БД
      // `

      // }
      // if (this.$store.state.connections.marketplace.title === "OZON") {
      //     response = await this.$store.dispatch(
      //         "connections/importOzProductsState",
      //         {
      //             hash: this.hash,
      //         }
      //     );
      // }

      //console.log("loadProgress: this.res2=", this.res2);

      this.loading = this.res2.continue == 1;
      if (!this.loading) {
        let res = {
          ...this.res1,
          ...this.res2
        };
        //console.log("loadProgress: res=", res);

        this.file = null;
        this.hash = null;
        close();
        this.onComplete && this.onComplete(res);
        this.$emit("complete", res);
      }
    }
  },
  computed: {
    plural_totDupSku() {
      return plural(this.res1.totDupSku, ["{v} дубль sku", "{v} дубля sku", "{v} дублей sku"]);
    },
    actions() {
      return ["cancel", {
        name: "submit",
        text: "Импортировать",
        // text: () => {
        //     this.loading ? "loading ..." : "Импортировать"
        // },

        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid,
        loading: this.loading
      }];
    }
  },
  components: {
    Modal,
    ImportBtn2
  }
};