//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
export default {
  mixins: [ModalMixin],
  props: {
    shedule: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: null,
      message: null
    };
  },
  mounted() {
    if (this.shedule == 'onDemand') {
      this.title = "Список готов к работе";
      this.message = `<center>Для проверки цен по вашему списку товаров нажмите <b>Обновить все цены</b>
                <center>`;
    } else {
      this.title = "Первичная загрузка цен";
      this.message = `<center>Сейчас мы выполняем первичный сбор цен по вашим товарам.<br>
                Далее проверка цен будет проходить по заданному в настройках расписанию.
                <center>`;
    }
  },
  methods: {
    // onOpen({ onComplete }) {
    //     this.onComplete = onComplete;
    // },
    async submit({
      close
    }) {
      close();
    }
  },
  computed: {
    actions() {
      return [{
        name: "submit",
        text: "Спасибо",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        }
      }];
    }
  },
  components: {
    Modal
  }
};